import {
  SwapTokenType,
  TokenSetIdp,
  createRetryFetch$,
  defaultAudiencesByClientType,
  flog,
  logOut,
  packWebTokenBundleIdp,
  signalLogIn,
} from '@heimdall/client';
import { Observable, catchError, defer, from, map, of, switchMap } from 'rxjs';
import {
  InteractionState,
  RedirectionStateFields,
  finalizeLogin,
} from './internal.js';
import { getLocalFields } from './local-fields.js';

export function createLoginRedirect$(): Observable<InteractionState> {
  return redirectionFields$.pipe(
    switchMap((fields) => {
      if (!fields) {
        return of<InteractionState>({
          state: 'ERROR' as const,
          error: new Error('Invalid redirection fields'),
        });
      }

      if ('error' in fields) {
        return of<InteractionState>({
          state: 'ERROR' as const,
          error: new Error(fields.error),
        });
      }

      const {
        idpKey,
        clientUid,
        clientId,
        tokenEndpoint,
        nextPath,
        heimdallTokenEndpoint,
        swapTokenType = 'id_token',
        codeVerifier,
        redirectUri,
        dpopMode,
        clientType,
        code,
      } = fields;

      const idpSwapParams = packTokenExchangeParams({
        grantType: 'authorization_code',
        redirectUri,
        clientId,
        code,
        codeVerifier,
      });

      return createRetryFetch$<TokenSetIdp>(tokenEndpoint, {
        method: 'POST',
        body: idpSwapParams,
        mode: 'cors',
      }).pipe(
        map((set) => {
          return packWebTokenBundleIdp(tokenEndpoint, set, {
            idpKey,
            clientUid,
            tokenEndpoint,
            heimdallTokenEndpoint,
            swapTokenType,
            defaultAudiences: defaultAudiencesByClientType(clientType),
            dpopMode,
          });
        }),
        finalizeLogin(nextPath)
      );
    }),
    flog('Login redirect'),
    catchError((error) => {
      console.warn(`Error logging in:`, error);
      return from(logOut()).pipe(
        switchMap(() => {
          return of<InteractionState>({
            state: 'ERROR',
            error,
          });
        })
      );
    }),
    signalLogIn()
  );
}

export const redirectionFields$ = defer(() => of(getRedirectionFields())).pipe(
  flog('Redirection fields')
);

function getRedirectionFields() {
  const params = new URLSearchParams(document.location.search);
  const code = params.get('code');
  const state = params.get('state');

  const {
    codeVerifier,
    redirectUri,
    idpEndSessionEndpoint,
    heimdallTokenEndpoint,
    swapTokenType,
    heimdallTokenLogoutUri,
    clientType,
    dpopMode,
    linkOriginKey,
  } = getLocalFields();

  const error = params.get('error');
  const errorDescription = params.get('error_description');

  if (error && errorDescription) {
    return {
      error,
      errorDescription,
      linkOriginKey,
    };
  }

  if (
    !code ||
    !state ||
    !codeVerifier ||
    !redirectUri ||
    !heimdallTokenEndpoint
  )
    return null;

  const { idpKey, clientUid, clientId, tokenEndpoint, nextPath } =
    decodeRedirectionState(state);

  return {
    idpKey,
    code,
    clientId,
    clientUid,
    tokenEndpoint,
    nextPath,
    codeVerifier,
    redirectUri,
    heimdallTokenEndpoint,
    dpopMode,
    clientType,
    ...(swapTokenType ? { swapTokenType } : {}),
    ...(heimdallTokenLogoutUri ? { heimdallTokenLogoutUri } : {}),
    ...(idpEndSessionEndpoint ? { idpEndSessionEndpoint } : {}),
    ...(linkOriginKey ? { linkOriginKey } : {}),
  };
}
function decodeRedirectionState(encodedFields: string) {
  return JSON.parse(decodeURI(encodedFields)) as RedirectionStateFields;
}

export function packTokenExchangeParams(fields: TokenExchangeFields) {
  const {
    grantType,
    redirectUri,
    codeVerifier,
    code,
    clientId,
    clientUid,
    deviceId,
    ...rest
  } = fields;

  let base = {};

  if (deviceId) {
    base = { ...base, device_id: deviceId };
  }

  if (clientId) {
    base = { ...base, client_id: clientId };
  }

  if (clientUid) {
    base = { ...base, client_uid: clientUid };
  }

  return new URLSearchParams({
    ...base,
    grant_type: grantType,
    redirect_uri: redirectUri,
    code_verifier: codeVerifier,
    code,
    ...rest,
  });
}

type TokenExchangeFields = {
  grantType: string;
  redirectUri: string;
  codeVerifier: string;
  code: string;
  clientId?: string;
  clientUid?: string;
  deviceId?: string;
  swapTokenType?: SwapTokenType;
};
