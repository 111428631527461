import { from, map } from 'rxjs';
import { audienceResourcePrefix, TokenAudience } from './internal.js';
import { flog } from './helpers.js';

export function createActiveAudiences$() {
  return from(navigator.locks.query()).pipe(
    map(toActiveAudiences),
    flog('Active audiences')
  );
}

function toActiveAudiences(snapshot: LockManagerSnapshot) {
  const activeAudiences = [
    ...(snapshot.held || []),
    ...(snapshot.pending || []),
  ]
    .map((info: LockInfo) => info.name || '')
    .filter((name) => name.startsWith(audienceResourcePrefix))
    .map((name) => name.slice(audienceResourcePrefix.length) as TokenAudience);
  return activeAudiences;
}
