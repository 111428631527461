import {
  alignLocalStorageValue,
  defaultAudiencesByClientType,
  flog,
  logOut,
  packNativeTokenBundleIdp,
  setVersionContext,
} from '@heimdall/client';
import { app, authentication } from '@microsoft/teams-js';
import {
  MonoTypeOperatorFunction,
  Observable,
  catchError,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AppConfig, appConfig$ } from './config.js';
import {
  IdpContext,
  InteractionState,
  createIssuerConfig$,
  finalizeLogin,
  validateNextPath,
} from './internal.js';

export function createTeamsLoginStart$(): Observable<InteractionState> {
  return appConfig$.pipe(
    validateNextPath(),
    validateClientUid(),
    tap(() => setVersionContext('TEAMS')),
    switchMap(({ issuerUrl, nextPath, clientUid, env }) => {
      alignLocalStorageValue('heimdall:env', env);

      return forkJoin([
        createIssuerConfig$(issuerUrl, clientUid).pipe(flog('Issuer Config')),
        getTeamsToken$().pipe(validateTokenPresent()),
      ]).pipe(
        map(
          ([
            {
              token_endpoint: heimdallTokenEndpoint,
              idps,
              dpop: dpopMode,
              client_type: clientType,
            },
            nativeToken,
          ]) => {
            const entraIdpContext: IdpContext | undefined = idps.entra;
            const swapTokenType = entraIdpContext?.swap || 'access_token';
            const defaultAudiences = defaultAudiencesByClientType(clientType);

            return packNativeTokenBundleIdp(
              {
                idpKey: 'entra',
                dpopMode,
                clientUid,
                defaultAudiences,
                heimdallTokenEndpoint,
                swapTokenType,
              },
              nativeToken
            );
          }
        ),
        finalizeLogin(nextPath)
      );
    }),
    flog('Teams login'),
    catchError((error) => {
      console.warn(`Error logging in:`, error);
      return from(logOut()).pipe(
        switchMap(() => {
          return of<InteractionState>({
            state: 'ERROR',
            error,
          });
        })
      );
    })
  );
}

function validateTokenPresent(): MonoTypeOperatorFunction<string> {
  return (source$) => {
    return source$.pipe(
      tap((token) => {
        if (!token) {
          throw new Error(
            'Token not present. Please authenticate and try again.'
          );
        }
      })
    );
  };
}

function validateClientUid(): MonoTypeOperatorFunction<AppConfig> {
  return (source$) => {
    return source$.pipe(
      tap((config) => {
        if (!config.clientUid) {
          throw new Error('Missing clientUid query param.');
        }
      })
    );
  };
}

function getTeamsToken$(): Observable<string> {
  return from(
    app.initialize().then(() => {
      return authentication.getAuthToken().then((teamsToken) => {
        return teamsToken;
      });
    })
  ).pipe(flog('Teams token'));
}
